import React, {Component} from 'react';
import Layout from '../layout';
import PostHome from '../post-home';

class BusquedaPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
          ssrDone: false
        }
    }

    renderPosts = (post) => {
        return (<PostHome post={post} bottom={true}/>);
    }

    render() {
        if(!this.state.ssrDone) {
            return (
              <div>cargando...</div>
            )
        }
        return(
            <Layout>
                <h2 className={"search-resultado"}>
                    Resultado de busqueda <span className={"search-busqueda"}>"{this.state.title}"</span> 
                </h2>
                {this.state.searchResults.map(this.renderPosts)}
            </Layout>
        )
    }

    componentDidMount(){
        let url_string = window.location.href;
        let url = new URL(url_string);
        let title = url.searchParams.get("titulo");
        if(!title) {
            window.location.href = '/';
        }
        let filtered = [];
        this.props.data.forEach(p => {
            if(p.title.toLowerCase().includes(title)) {
                filtered.push(p);
            }
        })
        this.setState({ssrDone: true, searchResults: filtered})
    }

}

export default BusquedaPage;
