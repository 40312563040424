import React from "react"
import { graphql } from "gatsby"
import BusquedaPage from "../components/busqueda-page";

export default ({ data }) => <BusquedaPage data={data.allWpPost.nodes}/>

export const query = graphql`
{
  allWpPost(filter: {}) {
    nodes {
      uri
      id
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          id
          name
          slug
        }
      }
      title
      excerpt
      content
      featuredImage {
        node {
          id
          sourceUrl
          localFile {
            childImageSharp {
              id
              fluid(maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      autorxs {
        authors {
          name
          customProfileImage
        }
      }
      copete {
        copete
      }
    }
  }
}
`
